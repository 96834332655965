@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat" !important;
}

ul,
p {
  margin: 0;
  padding: 0;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
  transition: none !important;
}

.styles-module_wrapper__1I_qj{
  z-index: 99 !important;
}
.styles-module_navigation__1pqAE{
  z-index: 99 !important;
  opacity: 1 !important;
  color: #ed7d2b !important;
}

span.styles-module_close__2I1sI.react-simple-image-viewer__close {
  background-color: gray !important;
  border-radius: 50% !important; 
  font-size: 2.6rem !important; 
  color: white !important; 
  padding: 0px 20px !important; 
}

.select-arabic .css-2613qy-menu{
  text-align: right !important;
}
@media (max-width: 835px) {
  .nav-link {
    padding: 0.5rem 0.6rem;
  }
}
@media (max-width: 402px) {
  .nav-link {
    padding: 0.5rem 0.8rem;
  }
}
@media (max-width: 339px) {
  .nav-link {
    padding: 0.5rem 0.2rem;
  }
}

.selectCur{
  width: 100%;
  border: 1px solid #e7e6e6;
  padding: 10px 10px;
  border-radius: 4px;
}